<template>
  <div v-if="true" class="tc-wrapper">
    <div class="top-title_new">
      <img src="../../../assets/image/common/vip-pay-top.png" alt="">
      <span class="vip-title-desc">开通会员，解锁6大权益</span>
    </div>
    <img class="vip-desc-items" src="../../../assets/image/common/vip-desc-items.svg" alt="">
    <div class="vip-sub-qa">
      <div class="qa-item">根据您的业务需求，购买不同的产品套餐，支持开具电子/纸质发票。</div>
      <div class="qa-item">系统个性定制或购买问题，请联系微信客服keyanpro</div>
    </div>
    <div class="vip-items">
      <div class="vip-item" :class="[item.id === currentSelItemId ? 'vip-item-active':'']" v-for="item in vipArr" :key="item.id" @click="vipItemClick(item)">
        <div class="vip-item-name">{{item.show_period}}</div>
        <div class="vip-item-notes">{{ item.notes }}</div>
        <div class="vip-item-price">
          <span>￥</span>
          <span>{{Number(item.money).toFixed(0)}}</span>
          <span>元</span>
        </div>
        <div class="vip-item-paybtn" @click="qrCodePay(item)">立即开通</div>
        <img class="vip-item-tag" v-if="item.actTag" :src="require(`../../../assets/image/common/vip-item-tag-${item.actTag}.svg`)" alt="">
      </div>
    </div>
    <div class="vip-warn-tip">
      *此会员为虚拟商品，一旦开通概不退款；开具纸质发票需自行支付邮资。
    </div>
    <!--  首页不显示  -->
    <div v-if="$route.path.indexOf('homePage') == -1" class="bottom">
      <div class="bottom-top">
        <div class="top-title">
          <img src="../../../assets/image/vip/礼物.png" alt="" />
          限时免费升级计划
        </div>
        <div class="top-content">
          即日起，凡通过分享邀请新用户注册科研人（含小程序）者，双方均可免费获取7天PRO会员资格，人数无限制，赠送天数可累加，点击下方按钮即可发送邀请信息。
        </div>
      </div>
      <div class="bottom-middle" @click="sendInvitePoster">发送邀请海报</div>
      <div class="bottom-bottom">
        TIPS：分享到朋友圈或科研群/论坛能获得更多机会哦，分享课题信息享受同等待遇~<br />
        *本活动限时有效，科研人保留最终解释权
      </div>
    </div>
    <div class="control-dialog">
      <div class="control-dialog" :class="{ hiddediv: ishiddenpaycode }">
        <div class="dialog" @click.self="closepaycode($event)">
          <div
            v-show="statusforpay == 0"
            class="turnout-wrapper turnin-wrapper paycode"
            style="width: 3.5rem; height: 3.5rem;background:#fff;"
          >
            <!-- :class="{'hide':isshowpaycode}" -->
            <div class="qrcode-style" id="qrcode" ref="paycode"></div>
            <div class="codetext">扫码支付</div>
            <div>
              <span class="button-zf" @click.stop="zhifucg">支付完毕</span>
            </div>
            <div
              style="color: rgb(255, 0, 0);
                padding-top: 20px;
                width: 90%;
                line-height: 1.5;
              "
            >
              友情提醒：*此会员为虚拟商品，一旦开通概不退款；开具纸质发票需自行支付邮资;
            </div>
          </div>
          <div
            v-show="statusforpay == 1"
            class="turnout-wrapper turnin-wrapper paycode"
            style="background:#fff;"
          >
            <img
              class="zfimg"
              src="../../../assets/image/vip/支付成功.png"
              alt=""
            />
            <div class="codetext">支付成功</div>

            <div class="button-zf" @click="successpay">确定</div>
          </div>
          <div
            class="turnout-wrapper turnin-wrapper paycode"
            v-show="statusforpay == 2"
            style="background:#fff;"
          >
            <img
              class="zfimg"
              src="../../../assets/image/vip/支付失败.png"
              alt=""
            />
            <div class="codetext">支付失败</div>

            <div class="button-zf" @click="paymoney1">重新支付</div>
          </div>
        </div>
      </div>
    </div>
    <div class="control-dialog">
      <div class="control-dialog" :class="{ hiddediv: ishidewxcode }">
        <div class="dialog" @click="closewxcode">
          <div class="turnout-wrapper turnin-wrapper paycode">
            <img src="../../../assets/image/haibao.png" alt=""/>
            <div class="z_ewm">
              <!-- <img src="../../../assets/image/关注公众号.png" alt="" /> -->
              <!-- <div id="qrcode1"></div> -->
              <!-- <div style="display:none;"> -->
              <vue-qr
                :logoSrc='imgUrl'
                :text="qrcodes"
                :size="119"
                :margin="0"
              ></vue-qr>
              <!-- </div> -->
            </div>
            <!-- <div
              class="qrcode-style"
              id="qrcode1"
              ref="paycode"
            ></div> -->

            <!-- <div class="codetext">扫码进入微信分享</div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="typeArea" v-else>
    <div class="top">
      <div class="top-header">用户定价</div>
      <div class="top-content">
        根据您的业务需求，购买不同的产品套餐，支持开具电子/纸质发票。<br>系统个性定制，请联系微信客服keyanpro
      </div>
    </div>
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <div class="middle-left">
            <div class="left-header">
              <img src="../../../assets/image/vip/适用会员.png" alt="" />
            </div>
            <div class="left-content">
              <div class="content-text">注册会员每天均有<br />免费查看额度</div>
              <div class="content-button" @click="toRegistered">立即注册</div>
            </div>
          </div>
        </div>
        <div class="swiper-slide swiper-slide-active" style="margin: 0 30px">
          <div class="middle-center">
            <div class="center-header">
              <img src="../../../assets/image/vip/pro会员.png" alt="" />
            </div>
            <div class="center-text">
              <div class="price">
                <!-- <span>{{z_qian}}</span> -->
                <span>{{money}}</span>
                元/1年
              </div>
              <div class="del"><del>{{notes}}</del></div>
              <div class="text-item">
                <div class="img">
                  <img src="../../../assets/image/vip/对勾绿色.png" alt="" />
                </div>
                无阅读量限制
              </div>
              <div class="text-item">
                <div class="img">
                  <img src="../../../assets/image/vip/对勾绿色.png" alt="" />
                </div>
                无翻页限制
              </div>
              <div class="text-item">
                <div class="img">
                  <img src="../../../assets/image/vip/对勾绿色.png" alt="" />
                </div>
                超大关键词订阅量
              </div>
              <div class="text-item">
                <div class="img">
                  <img src="../../../assets/image/vip/对勾绿色.png" alt="" />
                </div>
                超大机构订阅量
              </div>
              <div class="center-button" @click="paymoney">立即成为pro会员</div>
            </div>
          </div>
        </div>
        <div class="swiper-slide">
          <div class="middle-right">
            <div class="right-header">
              <img src="../../../assets/image/vip/机构会员.png" alt="" />
            </div>
            <div class="right-content">
              <div class="content-top">
                <div class="price">
                  <span>{{money1}}</span>
                  元/3年
                </div>
                <div class="del"><del>{{notes1}}</del></div>
              </div>
              <div class="content-bottom">超值折扣，更多人选择</div>
              <div class="content-button" @click="z_paymoney">立即购买</div>
            </div>
          </div>
        </div>
      </div>
      <!-- Add Pagination -->
      <div class="swiper-pagination"></div>
    </div>
    <div class="z_hui">*此会员为虚拟商品，一旦开通概不退款；开具纸质发票需自行支付邮资。</div>
    <div class="control-dialog">
      <div class="control-dialog" :class="{ hiddediv: ishiddenpaycode }">
        <div class="dialog" @click.self="closepaycode($event)">
          <div
            v-show="statusforpay == 0"
            class="turnout-wrapper turnin-wrapper paycode"
            style="width: 3.5rem; height: 3.5rem;background:#fff;"
          >
            <!-- :class="{'hide':isshowpaycode}" -->
            <div class="qrcode-style" id="qrcode" ref="paycode"></div>
            <div class="codetext">扫码支付</div>
            <div>
              <span class="button-zf" @click.stop="zhifucg">支付完毕</span>
            </div>
            <div
              style="color: rgb(255, 0, 0);
                padding-top: 20px;
                width: 90%;
                line-height: 1.5;
              "
            >
              友情提醒：*此会员为虚拟商品，一旦开通概不退款；开具纸质发票需自行支付邮资;
            </div>
          </div>
          <div
            v-show="statusforpay == 1"
            class="turnout-wrapper turnin-wrapper paycode"
             style="background:#fff;"
          >
            <img
              class="zfimg"
              src="../../../assets/image/vip/支付成功.png"
              alt=""
            />
            <div class="codetext">支付成功</div>

            <div class="button-zf" @click="successpay">确定</div>
          </div>
          <div
            class="turnout-wrapper turnin-wrapper paycode"
            v-show="statusforpay == 2"
             style="background:#fff;"
          >
            <img
              class="zfimg"
              src="../../../assets/image/vip/支付失败.png"
              alt=""
            />
            <div class="codetext">支付失败</div>

            <div class="button-zf" @click="paymoney1">重新支付</div>
          </div>
        </div>
      </div>
    </div>
    <div class="control-dialog">
      <div class="control-dialog" :class="{ hiddediv: ishidewxcode }">
        <div class="dialog" @click="closewxcode">
          <div class="turnout-wrapper turnin-wrapper paycode">
             <img src="../../../assets/image/haibao.png" alt=""/>
             <div class="z_ewm">
                <!-- <img src="../../../assets/image/关注公众号.png" alt="" /> -->
                <!-- <div id="qrcode1"></div> -->
              <!-- <div style="display:none;"> -->
                <vue-qr
                :logoSrc='imgUrl'
                :text="qrcodes"
                :size="119"
                :margin="0"
              ></vue-qr>
              <!-- </div> -->
             </div>
            <!-- <div
              class="qrcode-style"
              id="qrcode1"
              ref="paycode"
            ></div> -->

            <!-- <div class="codetext">扫码进入微信分享</div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="bottom">
      <div class="bottom-top">
        <div class="top-title">
          <img src="../../../assets/image/vip/礼物.png" alt="" />
          限时免费升级计划
        </div>
        <div class="top-content">
          即日起，凡通过分享邀请新用户注册科研人（含小程序）者，双方均可免费获取7天PRO会员资格，人数无限制，赠送天数可累加，点击下方按钮即可发送邀请信息。
        </div>
      </div>
      <div class="bottom-middle" @click="sendInvitePoster">发送邀请海报</div>
      <div class="bottom-bottom">
        TIPS：分享到朋友圈或科研群/论坛能获得更多机会哦，分享课题信息享受同等待遇~<br />
        *本活动限时有效，科研人保留最终解释权
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import { topay, ispaysuccess, z_mony,z_mony1,topay1,shareHai } from "../../../api/vip/vip";
import Swiper from "swiper";
import {
  Addhistoricalrecord,
  Addhistoricalrecordzc,
  Addhistoricalrecordky,
  Addhistoricalrecordwz,
} from "../../../api/myRecentInformation";
import vueQr from 'vue-qr'
export default {
  name: "vip",
  data() {
    return {
      currentSelItemId: null,
      address: "weixin://wxpay/bizpayurl?pr=f64PPkOzz",
      // 控制付款窗口显示隐藏
      ishiddenpaycode: true,
      // 控制邀请海报显示隐藏
      ishidewxcode: true,
      // 订单号
      orderoid: "",
      // 是否支付成功展示页面
      statusforpay: 0,
      z_qian: "",
      z_qian11:[],
      vipArr: [], // vip套餐
      money:"",
      money1:"",
      notes:"",
      notes1:"",
      term:"",
      term1:"",
      codeUrl: '我好看吗？', // 二维码地址或内容
      imgUrl: require('../../../assets/image/vip/二维码logo.jpg'), // 二维码中间的logo图
      exportLink: '',
      downloadFilename: '', // 下载的图片文件名
      qrcodes:'',
	  qrUrl:''
    };
  },
  components: {
    vueQr
  },
  created(){
        this.z_qian1();
  },
  mounted() {
    // eslint-disable-next-line no-new
    // new Swiper(".swiper-container", {
    //   slidesPerView: 3,
    //   spaceBetween: 30,
    //   centeredSlides: true,
    //   loop: true,
    //   pagination: {
    //     el: ".swiper-pagination",
    //     clickable: true,
    //   },
    // });
    // this.address = this.$route.params.address
	this.shareHai();
    this.qrcode();
    this.gundong();
  },
  methods: {
    vipItemClick(item) {
      this.currentSelItemId = item.id;
    },
    /**
     * 获取会员名
     * @param mon
     * @returns {string}
     */
    getVipTimeStr(mon) {
      if(mon < 12) {
        return `${mon}月会员`
      }
      return `${(mon / 12).toFixed(0)}年会员`
    },
		shareHai(){
			// debugger
			// console.log(JSON.parse(localStorage.getItem("usermessage").user_nickname))
			// const name = JSON.parse(localStorage.getItem("usermessage").user_nickname)
      const um = JSON.parse(localStorage.getItem("usermessage"));
      if(um) {
        const id = JSON.parse(localStorage.getItem("usermessage")).id
        shareHai({id:id,user_name: name}).then(res => {
          this.qrUrl = res.data
        })
      }
		},
    qrCodePay(item) {
      const that = this;
      if(JSON.parse(localStorage.getItem("usermessage"))){
        this.$data.ishiddenpaycode = false;
        const id = JSON.parse(localStorage.getItem("usermessage")).id;
        topay1({ id: id,money:item.money,time:item.days }).then(
          (res) => {
            console.log("成功", res);
            if (res.data) {
              that.$data.address = res.data;
            }
            console.dir(this.$refs.paycode);
            that.$refs.paycode.innerHTML = "";
            this.$data.orderoid = res.msg;
            this.qrcode();
          },
          (err) => {
            console.log("失败", err);
          }
        );
      }else{
        this.$router.push({
          path: "../../pc/loginroot/registered",
        });
      }
    },
    paymoney() {
      const that = this;
      if(JSON.parse(localStorage.getItem("usermessage"))){
        this.$data.ishiddenpaycode = false;
        const id = JSON.parse(localStorage.getItem("usermessage")).id;
        topay1({ id: id,money:that.money,time:that.term }).then(
          (res) => {
            console.log("成功", res);
            if (res.data) {
              that.$data.address = res.data;
            }
            console.dir(this.$refs.paycode);
            that.$refs.paycode.innerHTML = "";
            this.$data.orderoid = res.msg;
            this.qrcode();
        },
        (err) => {
          console.log("失败", err);
        }
      );
      }else{
        this.$router.push({
        path: "../../pc/loginroot/registered",
      });
      }
      // topay({ id: id }).then(
      //   (res) => {
      //     console.log("成功", res);
      //     if (res.data) {
      //       that.$data.address = res.data;
      //     }
      //     console.dir(this.$refs.paycode);
      //     that.$refs.paycode.innerHTML = "";
      //     this.$data.orderoid = res.msg;
      //     this.qrcode();
      //   },
      //   (err) => {
      //     console.log("失败", err);
      //   }
      // );

    },
    z_paymoney() {
      const that = this;
      if(JSON.parse(localStorage.getItem("usermessage"))){
      this.$data.ishiddenpaycode = false;
      const id = JSON.parse(localStorage.getItem("usermessage")).id;
      topay1({ id: id,money:that.money1,time:that.term1 }).then(
        (res) => {
          console.log("成功", res);
          if (res.data) {
            that.$data.address = res.data;
          }
          console.dir(this.$refs.paycode);
          that.$refs.paycode.innerHTML = "";
          this.$data.orderoid = res.msg;
          this.qrcode();
        },
        (err) => {
          console.log("失败", err);
        }
      );
      }else{
        this.$router.push({
        path: "../../pc/loginroot/registered",
      });
      }

    },
    paymoney1() {
      this.$data.ishiddenpaycode = false;
      const id = JSON.parse(localStorage.getItem("usermessage")).id;
      const that = this;
      topay({ id: id }).then(
        (res) => {
          console.log("成功", res);
          if (res.data) {
            that.$data.address = res.data;
          }
          console.dir(this.$refs.paycode);
          that.$refs.paycode.innerHTML = "";
          this.$data.orderoid = res.msg;
          this.qrcode();
        },
        (err) => {
          console.log("失败", err);
        }
      );
      this.$data.statusforpay = 0;
    },
    // 关闭支付弹窗
    closepaycode() {
      this.$data.ishiddenpaycode = true;
      this.$data.statusforpay = 0;
    },
    // 支付成功点击确定按钮
    successpay() {
      this.$data.ishiddenpaycode = true;
      this.$data.statusforpay = 0;
    },
    // 关闭分享界面
    closewxcode() {
      this.$data.ishidewxcode = true;
    },
    // 发送邀请海拔
    sendInvitePoster() {
      this.$data.ishidewxcode = false;
      const id = JSON.parse(localStorage.getItem('usermessage')).id
      // console.log(id)
      const that = this
      // that.$data.qrcodes = `https://keyanpro.com/?id=1&scene=${id}`
	  that.$data.qrcodes = 'https://keyanpro.com/' + that.qrUrl
      // that.$refs.paycode.innerHTML = ''
      // this.qrcode1()
    },
    qrcode() {
      const that = this;
      // eslint-disable-next-line no-unused-vars
      const qrcode = new QRCode("qrcode", {
        width: 120, // 设置宽度，单位像素
        height: 120, // 设置高度，单位像素
        text: that.$data.address, // 设置二维码内容或跳转地址,
      });
    },
    zhifucg() {
      const that = this;
      ispaysuccess({ order_id: that.$data.orderoid }).then(
        (res) => {
          console.log("支付是否成功", res.code);
          // eslint-disable-next-line eqeqeq
          if (res.code == 0) {
            this.$data.statusforpay = 2;
            // eslint-disable-next-line eqeqeq
          } else if (res.code == 1) {
            this.$data.statusforpay = 1;
            localStorage.setItem("ispro", true);
            that.$store.commit("setispro", true);
          }
        },
        (err) => {
          console.log("支付查询异常", err);
        }
      );
    },

    // qrcode1 () {
    //   const that = this
    //   // eslint-disable-next-line no-unused-vars
    //   const qrcode = new QRCode('qrcode1', {
    //     width: 120, // 设置宽度，单位像素
    //     height: 120, // 设置高度，单位像素
    //     text: that.$data.qrcodes // 设置二维码内容或跳转地址,
    //   })
    // },
    gundong() {
      if (this.$store.state.gundong == true) {
        window.scrollTo(0, 0);
      } else {
        window.scrollTo(0, 500);
      }
    },
    z_qian1() {
        const that = this;
      if(JSON.parse(localStorage.getItem("usermessage"))){
        const id = JSON.parse(localStorage.getItem("usermessage")).id;
        console.log("1111111qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq111", id);
        z_mony({ id: id }).then((res) => {
          that.z_qian = res.data;
        });
      }

       z_mony1().then((res) => {
        that.z_qian11 = res.data;
        res.data.forEach(item=> {
          if(item.term === 12) {
            item.actTag = 1;
          } else if(item.term === 60) {
            item.actTag = 2;
          }
        })
        this.vipArr = res.data;
        this.currentSelItemId = res.data[0].id
        that.money=res.data[0].money,
        that.money1=res.data[1].money,
        that.notes=res.data[0].notes,
        that.notes1=res.data[1].notes,
        that.term=res.data[0].days,
        that.term1=res.data[1].days
      });
    },
    toHaibao: function () {
      this.$router.push({
        path: "myHaibao",
      });
    },
    toRegistered: function () {
      if(this.$store.state.userid == ""){
          this.$router.push({
            path: "/pc/loginroot/registered",
          });
      }
    },
  },
};
</script>
<style lang="scss">
.top-title_new {
  height: 128px;
  width: 100%;
  margin-top: 80px;
  background: -webkit-linear-gradient(to right,rgba(243, 191, 118, 1) 0%, rgba(247, 225, 194, 1) 100%); /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(to right,rgba(243, 191, 118, 1) 0%, rgba(247, 225, 194, 1) 100%); /* Opera 11.1 - 12.0 */
  background: -moz-linear-gradient(to right,rgba(243, 191, 118, 1) 0%, rgba(247, 225, 194, 1) 100%); /* Firefox 3.6 - 15 */
  background: linear-gradient(to right,rgba(243, 191, 118, 1) 0%, rgba(247, 225, 194, 1) 100%); /* 标准的语法（必须放在最后） */
  position: relative;
  display: flex;
  justify-content: center;
  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 362px;
    background-image: url("~@/assets/image/common/vip-pay-top-bg.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
  }
  img {
    width: 136px;
    height: 88px;
    position: absolute;
    top: -34px;
  }
  span {
    position: absolute;
    bottom: 22px;
    font-size: 32px;
    font-weight: 900;
    color: #683406;
  }
}
.hiddediv {
  display: none;
}
.zfimg {
  width: 42px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.swiper-container {
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  width: 364px;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  transition: 300ms;
  .middle-left {
    // border: 1px solid #dedede;
    background: #FFFFFF;
box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .left-header {
      height: 64px;
      img {
        width: 100%;
      }
    }
    .left-content {
      width: 100%;
      flex: 1;
      margin-top: 66px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .content-text {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #000;
        span {
          font-size: 40px;
        }
      }
      .content-button {
        margin-top: 71px;
        height: 52px;
        width: 236px;
        box-sizing: border-box;
        border: 1px solid #707070;
        border-radius: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #707070;
        margin-bottom: 32px;
        cursor: pointer;
      }
    }
  }
  .middle-center {
    // border: 1px solid #dedede;
    background: #FFFFFF;
box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);
    width: 364px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: scaleY(0.72);
    .center-header {
      margin-bottom: 45px;
      img {
        width: 100%;
      }
    }
    .center-text {
      display: flex;
      flex-direction: column;
      align-items: center;
      .price {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #9E9C9D;
        margin-bottom: 15px;
        span {
          color: #000;
          font-size: 40px;
        }
      }
      .del {
        font-size: 12px;
        color: #a3a09b;
        margin-bottom: 20px;
      }
      .text-item {
        display: flex;
        justify-content: left;
        align-items: center;
        font-size: 16px;
        margin-bottom: 22px;
        width: 200px;
        white-space: nowrap;
        .img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: #00b67a;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 12px;
          img {
            width: 13px;
          }
        }
      }
      .center-button {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 8px;
        width: 236px;
        height: 52px;
        border-radius: 26px;
        // background-color: #5e068c;
        background: linear-gradient(180deg, #ffb855 0%, #ff7900 100%);
        color: #fff;
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        margin-bottom: 38px;
        font-weight: bold;
      }
    }
  }
  .middle-right {
    // border: 1px solid #dedede;
    background: #FFFFFF;
box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);
    width: 364px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .right-header {
      height: 64px;
      img {
        width: 100%;
      }
    }
    .right-content {
      width: 100%;
      flex: 1;
      margin-top: 35px;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #000;
      .content-top {
        font-size: 40px;
        // margin-bottom: 27px;
        .price {
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #9E9C9D;
          margin-bottom: 15px;
          span {
            font-size: 40px;
            color:#000;
          }
        }
        .del {
          font-size: 12px;
          color: #a3a09b;
          margin-bottom: 20px;
        }
      }
      .content-bottom {
        color: #333;
        font-size: 20px;
      }
      .content-button {
        margin-top: 30px;
        height: 52px;
        width: 236px;
        box-sizing: border-box;
        border: 1px solid #ED6B35;
        border-radius: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ED6B35;
        margin-bottom: 32px;
        cursor: pointer;
      }
    }
  }
}
.swiper-container-android .swiper-slide,
.swiper-wrapper {
  display: flex;
  align-items: center;
}
.swiper-slide-active,
.swiper-slide-duplicate-active {
  transform: scaleY(1.38);
  height: 470px;
}
</style>

<style lang="scss" scoped>
.hide {
  display: none;
}
// 版心
.typeArea {
  width: 1200px;
  margin: 0 auto;
  .top {
    margin-top: 77px;
    margin-bottom: 63px;
    .top-header {
      font-weight: bold;
      font-size: 30px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      text-align: center;
      margin-bottom: 35px;
    }
    .top-content {
      text-align: center;
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 34px;
      color: #000;
    }
  }
  .middle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .middle-left {
      border: 1px solid #dedede;
      width: 364px;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .left-header {
        height: 64px;
        img {
          width: 100%;
        }
      }
      .left-content {
        width: 100%;
        flex: 1;
        margin-top: 66px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .content-text {
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #000;
          line-height: 1.5;
          span {
            font-size: 40px;
          }
        }
        .content-button {
          margin-top: 71px;
          height: 52px;
          width: 236px;
          box-sizing: border-box;
          border: 1px solid #707070;
          border-radius: 26px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #707070;
          margin-bottom: 32px;
        }
      }
    }
    .middle-center {
      border: 1px solid #dedede;
      width: 364px;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .center-header {
        margin-bottom: 45px;
        img {
          width: 100%;
        }
      }
      .center-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        .price {
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #000;
          margin-bottom: 35px;
          span {
            font-size: 40px;
          }
        }
        .text-item {
          display: flex;
          justify-content: left;
          align-items: center;
          font-size: 16px;
          margin-bottom: 22px;
          .img {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: #00b67a;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 12px;
            img {
              width: 13px;
            }
          }
        }
        .center-button {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 8px;
          width: 236px;
          height: 52px;
          border-radius: 26px;
          background-color: #5e068c;
          color: #fff;
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          margin-bottom: 38px;
        }
      }
    }
    .middle-right {
      border: 1px solid #dedede;
      width: 364px;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .right-header {
        height: 64px;
        img {
          width: 100%;
        }
      }
      .right-content {
        width: 100%;
        flex: 1;
        margin-top: 35px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #000;
        .content-top {
          font-size: 40px;
          margin-bottom: 27px;
        }
        .content-bottom {
          color: #828282;
        }
        .content-button {
          margin-top: 50px;
          height: 52px;
          width: 236px;
          box-sizing: border-box;
          border: 1px solid #5e068c;
          border-radius: 26px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #5e068c;
          margin-bottom: 32px;
        }
      }
    }
  }
  .el-carousel__mask {
    .middle-left {
      border: 1px solid #dedede;
      width: 364px;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .left-header {
        height: 64px;
        img {
          width: 100%;
        }
      }
      .left-content {
        width: 100%;
        flex: 1;
        margin-top: 66px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .content-text {
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #000;
          span {
            font-size: 40px;
          }
        }
        .content-button {
          margin-top: 71px;
          height: 52px;
          width: 236px;
          box-sizing: border-box;
          border: 1px solid #707070;
          border-radius: 26px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #707070;
          margin-bottom: 32px;
        }
      }
    }
    .middle-center {
      border: 1px solid #dedede;
      width: 364px;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .center-header {
        margin-bottom: 45px;
        img {
          width: 100%;
        }
      }
      .center-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        .price {
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #000;
          margin-bottom: 35px;
          span {
            font-size: 40px;
          }
        }
        .text-item {
          display: flex;
          justify-content: left;
          align-items: center;
          font-size: 16px;
          margin-bottom: 22px;
          .img {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: #00b67a;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 12px;
            img {
              width: 13px;
            }
          }
        }
        .center-button {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 8px;
          width: 236px;
          height: 52px;
          border-radius: 26px;
          background-color: #5e068c;
          color: #fff;
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          margin-bottom: 38px;
        }
      }
    }
    .middle-right {
      border: 1px solid #dedede;
      width: 364px;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .right-header {
        height: 64px;
        img {
          width: 100%;
        }
      }
      .right-content {
        width: 100%;
        flex: 1;
        margin-top: 35px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #000;
        .content-top {
          font-size: 40px;
          margin-bottom: 27px;
        }
        .content-bottom {
          color: #828282;
        }
        .content-button {
          margin-top: 50px;
          height: 52px;
          width: 236px;
          box-sizing: border-box;
          border: 1px solid #5e068c;
          border-radius: 26px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #5e068c;
          margin-bottom: 32px;
        }
      }
    }
  }
  .bottom {
    border-radius: 10px;
    padding: 36px 32px;
    padding-bottom: 24px;
    margin-top: 54px;
    margin-bottom: 30px;
    background-color: #fffaf2;
    border: 1px dashed #d6b470;
    .bottom-top {
      .top-title {
        display: flex;
        align-items: center;
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #c87f00;
        margin-bottom: 20px;
        img {
          width: 19.61px;
          height: 17.86px;
          margin-right: 14px;
        }
      }
      .top-content {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 34px;
        color: #ca963b;
        margin-bottom: 18px;
      }
    }
    .bottom-middle {
      margin: 0 auto;
      width: 235px;
      height: 52px;
      border-radius: 26px;
      background-color: #ffcb83;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 19px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #000000;
      margin-bottom: 35px;
    }
    .bottom-bottom {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 24px;
      color: #666666;
    }
  }

}
.z_hui{text-align: center;font-size: 14px;color: #A3A3A3;position: relative;top: 20px;}
.z_ewm{position: absolute;width: 80px;height: 80px;right: 303px;bottom: 45px;}

.tc-wrapper {
  background-color: #FFF;
  box-shadow: 0px 0px 16px  rgba(239, 174, 100, 0.16);
  border-radius: 4px;
  .bottom {
    border-radius: 10px;
    padding: 36px 32px;
    padding-bottom: 24px;
    margin-top: 54px;
    margin-bottom: 30px;
    background-color: #fffaf2;
    border: 1px dashed #d6b470;
    .bottom-top {
      .top-title {
        display: flex;
        align-items: center;
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #c87f00;
        margin-bottom: 20px;
        img {
          width: 19.61px;
          height: 17.86px;
          margin-right: 14px;
        }
      }
      .top-content {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 34px;
        color: #ca963b;
        margin-bottom: 18px;
      }
    }
    .bottom-middle {
      margin: 0 auto;
      width: 235px;
      height: 52px;
      border-radius: 26px;
      background-color: #ffcb83;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 19px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #000000;
      margin-bottom: 35px;
    }
    .bottom-bottom {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 24px;
      color: #666666;
    }
  }
}

.vip-desc-items {
  width: calc(100% - 2*96px);
  margin: 68px 96px 45px 96px;
}
.vip-sub-qa {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .qa-item {
    font-size: 18px;
    color: #333;
    line-height: 32px;
  }
}
.vip-items {
  display: flex;
  gap: 16px;
  padding: 0 54px;
  margin-top: 68px;
  .vip-item {
    position: relative;
    cursor: pointer;
    flex: 1;
    border: 2px solid #E3E3E3;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 36px 0 45px 0;
    .vip-item-tag {
      position: absolute;
      top: -18px;
      right: -4px;
    }
    .vip-item-name {
      margin-bottom: 18px;
      font-size: 22px;
      color: #141617;
      font-weight: 500;
    }
    .vip-item-notes {
      color: #A3A09B;
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 24px;
    }
    .vip-item-price {
      color: #FF7D18;
      letter-spacing: 0px;
      line-height: 53px;
      text-align: left;
      vertical-align: bottom;
      display: flex;
      gap: 2px;
      margin-bottom: 28px;
      span:nth-child(1) {
        font-size: 24px;
        font-weight: 400;
        display: flex;
        align-items: flex-end;
        line-height: 45px;
      }
      span:nth-child(2) {
        font-size: 40px;
        font-weight: 700;
        display: flex;
        align-items: flex-end;
      }
      span:nth-child(3) {
        font-size: 24px;
        font-weight: 400;
        display: flex;
        align-items: flex-end;
        line-height: 48px;
      }
    }
    .vip-item-paybtn {
      border: 1px solid #FF7D18;
      color: #FF801D;
      text-align: center;
      width: calc(100% - 2*28px);
      margin: 0 auto;
      height: 46px;
      line-height: 46px;
      border-radius:4px ;
      font-size: 14px;
      letter-spacing: 1.1px;
    }
  }
  .vip-item:hover {
    border-color: #FF7D18;
    background-color: #FFFAF4;
    .vip-item-paybtn {
      background-color: #FF7D18;
      color: white;
    }
  }
  .vip-item-active {
    border-color: #FF7D18;
    border-width: 2px;
    background-color: #FFFAF4;
    .vip-item-paybtn {
      background-color: #FF7D18;
      color: white;
    }
  }
}
.vip-warn-tip{
  padding: 32px 0 42px 0;
  color: #A3A3A3;
  font-size: 14px;
  text-align: center;
}
.control-dialog {
  .dialog {
    z-index: 99;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    background: rgba($color: #000000, $alpha: 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    .paycode {
      z-index: 1;
      // width: 300px;
      // height: 300px;
      width:1000px;
      height: 90vh;
      border: 1px solid #5e068c;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // background-color: #fff;
      position: relative;
      img{max-height: 100%;max-width: 100%;}
      .codetext {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #5e068c;
        margin-top: 20px;
      }
      .button-zf {
        cursor: pointer;
        background-image: -webkit-linear-gradient(223deg, #7c34a1, #ff8063);
        background-image: linear-gradient(-133deg, #7c34a1, #7c34a6);
        border-radius: 80px;
        font-size: 16px;
        color: #fff;
        text-align: center;
        padding: 9px 45px;
        display: block;
        width: 80px;
        margin: 0 auto;
        margin-top: 20px;
      }
    }
  }
}
</style>
